import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Using Data Science to inform Speedrunning",
  "details": "Click here for the best frog collecting route",
  "date": "2022-08-12",
  "tags": ["MST and Webscraping", "Genshin Impact", "Speedrunning", "Gaming"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Speedrun"
      }}>{`Speedrunning`}</a>{` is pretty awesome. I've always been fascinated with learning new tricks, planning new routes, or optimizing my play just to shave those few precious seconds off my personal best time. It's competitive, challenging, and all about improvement.`}</p>
    <p>{`Browsing through the categories for some of my favorite games, I stumbled across a somewhat peculiar category for Genshin Impact: `}<a parentName="p" {...{
        "href": "https://www.speedrun.com/gi#Elite_Enemies"
      }}>{`Defeat X Elite Enemies`}</a>{`. X can be 100, 200, 400, or even 600 enemies!`}</p>
    <p>{`What excited me about this fairly ordinary task is just how many locations you'd have to visit on an open world map, and how we could potentially optimize this.`}</p>
    <p>{`What's the shortest path for each number of enemies? How can we take advantage of the fact that we can teleport? Can we develop tools which would do this for us algorithmically? Finally, can we expand this idea to other routes, like farming frogs? `}</p>
    <p>{`Of course we can, and that's what I'd like to present to you below!`}</p>
    <p>{`Note: If you're not interested in the data science story, skip right to the results/conclusions at the end!`}</p>
    <h2>{`Oh, where oh where could my data be?`}</h2>
    <p>{`A lot of Genshin Impact players will be familiar with an `}<a parentName="p" {...{
        "href": "https://genshin-impact-map.appsample.com/"
      }}>{`interactive map`}</a>{`. This is a useful tool for locating all kinds of resources. In our case, we're interested in `}<a parentName="p" {...{
        "href": "https://genshin-impact.fandom.com/wiki/Elite_Enemies"
      }}>{`elite enemies`}</a>{`.
The data for this map is stored in a marker json that is fetched on load in. This provides us with the locations of, well, everything! Neat! There's one small catch, which is of course these markers are not labeled. So we can't distinguish an elite enemy from a mint. So what are we to do? Luckily, the map accesses these markers with a unique identifier. But how to we get that info from the website? That's where webscraping comes in handy.`}</p>
    <h2>{`Webscraping, Selenium, and some BeautifulSoup`}</h2>
    <p>{`Selenium is generally used for browser automation, but is also a very useful tool in navigating a site and extracting information from it.
Looking at the Genshin Impact Interactive Map, several buttons can be observed which display their respective markers. By inspecting the elements of the page (F12), we can observe that the image associated with the button has an id. So our plan is to access each of those buttons, so we can map the name of the button to the id to the same id on the marker. This will provide us with the name of that marker location!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "750px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42.5531914893617%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAABJ0AAASdAHeZh94AAACMElEQVQozwElAtr9ALC3vrS7w6evuK60vNXX27XGx6OysKS2uJqutputtZystZiosaq3wNLV28PFy9LQ0snJzMrJzMi+v8jCxACqq7qlr7ansbmut8DO1dNOfHoLSEwNRE0FOUYENUQHM0QDL0IPOkwvVmUhNz8XFBQUFBUYGBkWGBcKCwoAzODu0+XtyN/szePv3eDjQGtxD0hTGUpWF0ZTFkRTFUNSGEVUGEtXDENSCis2AAAAAAEBAAAAAAAAMzIzAL29xujr7dfb3uHl6NnW2jxgaRJGUhlIVhdFVBVDUh5LWilZZU9zfUBkdA8tNQIAAAMEBAMDAwMEBFpbWwBNYXCDjZeWm6KYo63BwsQqPUIGPk4PQVENQVAOQ1IWUF1GeIGDiZVAbnsAISoAAAAAAQEAAAAAAAAAAAAAc4OTqbK54uPj5ubmq6qqWGRnXoONXX6IX4ONYIeQdpSehJ2onrG0bpKaV2huVFNSVFVVUE9RS0lLTk9PAP/9/fr0+PLy9/b1+Pf29v39/v/+/v///f///////////////v///v78+/r5+fv6+/r6+vX3+vb19vPy8gDJ4PnD1vXG2PTE2vTH3PbE2/XB2fHH3fXM4/nN4/nN4vnL4/rS5fnW5Pbn7fP18fb39vbj6/Le6vfl6/IA7/X95en36ev45uz45uz45e335Ov14+v17fT77vT87fT87vX88fX98vP68PLz8/L0+Pj57/X68/f89vf4135CD92xShoAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Inspect Button Element Example",
          "title": "Inspect Button Element Example",
          "src": "/static/4c2ab3c6976c306b88b514a7ffd79ff2/1d69c/inspect-genshin-element-example.png",
          "srcSet": ["/static/4c2ab3c6976c306b88b514a7ffd79ff2/4dcb9/inspect-genshin-element-example.png 188w", "/static/4c2ab3c6976c306b88b514a7ffd79ff2/5ff7e/inspect-genshin-element-example.png 375w", "/static/4c2ab3c6976c306b88b514a7ffd79ff2/1d69c/inspect-genshin-element-example.png 750w", "/static/4c2ab3c6976c306b88b514a7ffd79ff2/78797/inspect-genshin-element-example.png 1125w", "/static/4c2ab3c6976c306b88b514a7ffd79ff2/aa440/inspect-genshin-element-example.png 1500w", "/static/4c2ab3c6976c306b88b514a7ffd79ff2/29114/inspect-genshin-element-example.png 1920w"],
          "sizes": "(max-width: 750px) 100vw, 750px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Alright, so let's get scraping!`}</p>
    <p>{`Starting with some importing.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` selenium `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` webdriver
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` selenium`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`webdriver`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`common`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`by `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` By
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Waiting with conditions`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` selenium`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`webdriver`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`support`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ui `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` WebDriverWait
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` selenium`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`webdriver`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`support `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` expected_conditions `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` EC
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Loading data into csv format`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` csv`}</code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`Here, we request the site, and all its info loaded on the page. `}</p>
    <p>{`The wait at the end can be very useful. There are two kinds of waits: Implicit and Explicit. Implicit is a general wait. Explicit is used for specific conditions to be met before continuing. This can be very useful to stop the bot from sprinting ahead before something useful is loaded.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Constructs Chrome Driver`}</span>{`
driver `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` webdriver`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Chrome`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`executable_path`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'D:/DataScience/PycharmProjects/SeleniumDrivers/chromedriver.exe'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Requests from the url using driver`}</span>{`
driver`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`get`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"https://genshin-impact-map.appsample.com/"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Wait to load browser ~ implicit makes everything wait until ready`}</span>{`
driver`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`implicitly_wait`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`Now we grab all the images which contain a certain css styling. In this case, we want all the images which start with the link to a marker. It's nice to do this with a try catch because it will indicate if you've met a failed condition.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Create a list of img tags selected by CSS. In particular, we grab by having the src have .../markers`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    imgs `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` driver`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`find_elements`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`By`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`CSS_SELECTOR`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'img[src^="https://game-cdn.appsample.com/gim/markers/"]'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`except`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Those images weren't found. Skipping..."`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`At this point, we have all the elements stored in imgs. Now we want to access info from each of those elements and write them to a file.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# open the file in the write mode`}</span>{`
f `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`open`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'btn_mapping.csv'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'w'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` newline`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# create the csv writer`}</span>{`
writer `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` csv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`writer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`f`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Writing a header manually`}</span>{`
writer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`writerow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"type2"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"title"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Iterate through each img. Grab its src (o id) and alt (text)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` img `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` imgs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Grabbing attribute data`}</span>{`
    src `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` img`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`get_attribute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"src"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    alt `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` img`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`get_attribute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"alt"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#Printing`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-interpolation"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`f"`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`src`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`, `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`alt`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Writing`}</span>{`
    writer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`writerow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`src`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` alt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# close the file`}</span>{`
f`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`close`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}</code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`At this point, we've scraped all the info we needed off the Teyvat map. But what if we want other maps, like Enkanomiya, for example? We can actually tell Selenium to click on buttons to navigate the page!
If you're further interested in this, check out my `}<a parentName="p" {...{
        "href": "https://github.com/MarcoRSousa"
      }}>{`GitHub`}</a>{` for additional code accomplishing this task!`}</p>
    <p>{`For now, we got all the data we need, but it's so messy! Let's clean that up.`}</p>
    <h2>{`Data Cleaning`}</h2>
    <p>{`First, let's look at the two data sources we need to neaten up: The markers.json, and our scraped csv file.`}</p>
    <p>{`Markers:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Opening the json file`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`with`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`open`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'markers.json'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` json_data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    data `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` json`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`load`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`json_data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

df `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` pd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`DataFrame`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'data'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` columns `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'headers'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`id`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`mid`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`level`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`lng`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`lat`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2702`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`o6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-0.04151`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.03910`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2759`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`o6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-0.15001`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.08293`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Scraped Data: `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Loading in the marker data that was scraped off the site`}</span>{`
df_mapping `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` pd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`read_csv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'btn_mapping.csv'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`type2`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`title`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://game-cdn.appsample.com/gim/markers/o5.png"
            }}>{`https://game-cdn.appsample.com/gim/markers/o5.png`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`65 � Anemoculus`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://game-cdn.appsample.com/gim/markers/o6.png"
            }}>{`https://game-cdn.appsample.com/gim/markers/o6.png`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`131 � Geoculus`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://game-cdn.appsample.com/gim/markers/194.png"
            }}>{`https://game-cdn.appsample.com/gim/markers/194.png`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`181 � Electroculus`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://game-cdn.appsample.com/gim/markers/141.png"
            }}>{`https://game-cdn.appsample.com/gim/markers/141.png`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80 � Crimson Agate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`We can immediately see that there's quite a few things that are unsatisfying about this data.
First, the entire link associated with the type id. Next, the preceding numbers in the title, along with other symbols.
Let's clean that up.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Removing the link information from id str for better merging`}</span>{`
df_mapping`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'type2'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` df_mapping`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'type2'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`str`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`replace`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'https://game-cdn.appsample.com/gim/markers/'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` regex`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`True`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
df_mapping`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'type2'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` df_mapping`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'type2'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`str`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`replace`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'.png'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` regex`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`True`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Dropping duplicates`}</span>{`
df_mapping `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` df_mapping`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`drop_duplicates`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`subset`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'type2'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
df_mapping

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Cleaning the ASCII of the title column`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# The basic idea is to replace the non ascii with a ?, then replace any ? with whitespace`}</span>{`
df_mapping`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'title'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` df_mapping`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'title'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`str`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`encode`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'ascii'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'replace'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`str`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`decode`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'ascii'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
df_mapping`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'title'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` df_mapping`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'title'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`str`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`replace`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'?'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`' '`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` regex`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`True`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Left Stripping the numbers in front of some Strings`}</span>{`
df_mapping`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'title'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` df_mapping`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'title'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`str`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`lstrip`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'0123456789.- '`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`type2`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`title`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`o5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Anemoculus`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`o6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Geoculus`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`o194`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Electroculus`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`o141`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Crimson Agate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Ahh, now that's much better. Finally, let's merge this data into one neat dataframe.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Merging left. This preserves the marker data, and adds title information from df_mapping`}</span>{`
df_clean `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` df`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`merge`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`df_mapping`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` left_on`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'type'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` right_on`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'type2'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` how`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'left'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`Oh, and let's only look at Teyvat for now.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# mid refers to which map we are discussing. mid=2 refers to the base Teyvat map.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# The following is only the Teyvat Map`}</span>{`
teyvat `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` df_clean`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`df_clean`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'mid'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
teyvat`}</code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`Lastly, I'm going to take out all that other uneeded stuff by only grabbing teleporters and elite enemies.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`teleport `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` teyvat`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`teyvat`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'title'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`isin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'Statue of 7'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Teleporter'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Domain'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
elite_enemies `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` teyvat`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`teyvat`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'title'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`isin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'Mitachurl'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Abyss Mage'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Fatui Agent'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Fatui Cicin Mage'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Fatui Skirmisher'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Ruin Guard'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Ruin Hunter'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Ruin Sentinel'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Geovishap Hatchling'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Geovishap'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Fatui Mirror Maiden'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Wolves of the Rift'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Adding a status tag to differentiate between teleporting and fighting an enemy`}</span>{`
elite_enemies`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'status'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'elite enemy'`}</span>{`
teleport`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'status'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'teleport'`}</span>{`

teyvat_speedrun `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` pd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`concat`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`teleport`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` elite_enemies`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`reset_index`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`And that's the last of tedious data cleaning. Let's get routing!`}</p>
    <h2>{`MST and Prim's (heap)`}</h2>
    <p>{`The basic idea we want is to find the minimum path between N points. In graph theory, this is called a Minimum Spanning Tree (MST).
Generally, a MST is completely connected. However, in our case, we can teleport all over the place! To accommodate this, we can say the distance between teleporters is actually 0.`}</p>
    <p>{`I chose to use Prim's algorithm. Its idea is very simple. The essential technique is to calculate and locate the next nearest node, and add that to visited nodes, then repeat this procedure until you've met N nodes. Greedy algorithms are generally not preferable, but I think this suits our needs quite well; given the density of the data and how this encourages the algorithm to approach from the nearest teleporter or nearby location. When something is too far, it'll simply teleport away, which is the very kind of behavior we're looking for.`}</p>
    <p>{`Graph data is generally stored in one of two structures: A linked list or an adjacency matrix. I chose to implement a linked list.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Acquiring the points and the status from teyvat_speedrun dataframe`}</span>{`
points `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`y`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`status`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` y`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` status `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`zip`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`teyvat_speedrun`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'lng'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` teyvat_speedrun`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'lat'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` teyvat_speedrun`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'status'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Number of points`}</span>{`
N `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`len`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`points`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`euclidean_distance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`y1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`x2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`y2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token triple-quoted-string string"
          }}>{`"""Euclidean distance method."""`}</span>{`
    distance `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`sqrt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`x1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`**`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`y2`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`y1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`**`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` distance

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Adjacency list construction using dictionary`}</span>{`
linked `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`i`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` i `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`range`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`N`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Iterating through every point, calculating distance, and appending to our linked list structure`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Using range(i+1,N) only constructs upper triangular of adjacency matrix. Append creates lower.`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` i `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`range`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`N`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Take the x,y point for item pi`}</span>{`
    x1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` y1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` status1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` points`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`i`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` j `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`range`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`i`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`N`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        x2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` y2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` status2 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` points`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`j`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`status1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'teleport'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`status1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` status2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            dist `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
            linked`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`i`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`append`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`dist`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` j`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
            linked`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`j`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`append`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`dist`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` i`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            dist `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` euclidean_distance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`y1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`x2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`y2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
            linked`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`i`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`append`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`dist`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` j`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
            linked`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`j`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`append`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`dist`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` i`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`Finally, here's our Prim's algorithm.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`prims`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`linked`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`N`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Variables. minHeap is populated with [[cost,point,connection],...,[]]`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Note: Point is the newly added point. Connection is the old point its being connected to.`}</span>{`
    distance `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
    visited `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`set`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    minHeap `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    connections `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Looping until we reach all N nodes`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`while`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`len`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`visited`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` N`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# heappop returns the smallest element (cost) and its associated point`}</span>{`
        cost`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` point`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` connection `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` heapq`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`heappop`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`minHeap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` point `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` visited`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`continue`}</span>{`
        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Adding a node to visited and adding distance`}</span>{`
        distance `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` distance `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` cost
        visited`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`add`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`point`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        connections`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`append`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`connection`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`point`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Add neighbors to the minHeap using the newly added point`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` neiCost`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`neighbor `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` linked`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`point`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` neighbor `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`not`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` visited`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
                `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Adding to the minHeap if not already in`}</span>{`
                heapq`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`heappush`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`minHeap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`neiCost`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` neighbor`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` point`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` connections`}</code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p>{`Alright, awesome! This gives us all of our edge connections for up to N points. But how can we visualize this? I choose to do this with a package called networkx.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`graphRoute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`connections`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`N`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Creating a network from networkx`}</span>{`
    G `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` nx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Graph`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` connection `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` connections`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Adding only included nodes`}</span>{`
        G`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`add_node`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`connection`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        G`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`add_node`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`connection`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Adding edges from connections`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` connection `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` connections`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`175`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        G`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`add_edge`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`connection`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Constructing a dictionary of points from connections`}</span>{`
    pointsDict `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` c `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` connections`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        pointsDict`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`c`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`points`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`c`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`points`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`c`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
        pointsDict`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`c`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`points`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`c`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`points`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`c`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# This uses the dictionary to assign fixed position for the nodes`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# node0: [position, position]`}</span>{`
    fixed_positions `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` pointsDict
    fixed_nodes `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` pointsDict`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`keys`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    pos `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` nx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`spring_layout`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`G`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` pos`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`fixed_positions`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` fixed`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`fixed_nodes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# This defines a color mapping. I want the teleporters to be blue and the enemies red`}</span>{`
    color_map `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'blue'`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` node `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`175`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'red'`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` node `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` G`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# A large figure allows the points and connections to be more distinct`}</span>{`

    plt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`figure`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` figsize`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`40`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`40`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    nx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`draw`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`G`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` pos`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` with_labels`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`False`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` node_color`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`color_map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}</code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <h2>{`The Results`}</h2>
    <p>{`Now, let's put it all together! `}</p>
    <p>{`The blue points are points on the map we can teleport to, but don't need to visit. The red points are locations of elite enemies we need to defeat.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "style": {
          "counterReset": "linenumber NaN"
        },
        "className": "language-python line-numbers"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Another speedrunning route, only going to N points`}</span>{`
N `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`175`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`600`}</span>{`
connections `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` prims`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`linked`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`N`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
graphRoute`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`connections`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`N`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code><span parentName="pre" {...{
          "aria-hidden": "true",
          "className": "line-numbers-rows",
          "style": {
            "whiteSpace": "normal",
            "width": "auto",
            "left": "0"
          }
        }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "750px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACBElEQVQ4y5WUSW/bMBCF/f//UG+9FCjSHHpoi6RtUsRNvMeOo4U7OV8hWrYsOxZSAhSXId/Mm3nUSITcRCT3pkXjSNaDpMaS91KISIyHs5fa6BSMlNCzF8z8BbcpaLa9h/pZEZU9c34GmI2HpZCiNJi4yqLWiqoI1AZU4SG9I0JpgYLxJAG1LKkfn4nK4P7Oqb7do/5M8bUluIQkGQTNlP22opwW6K3GLze40mDGM8rrG6qnF8rbR8Q5kg85BQxRbj5m8oweL9A/7vDTBW5TZeAmlxIiSZsOIIMxXJSmlmG+Il5d49ZFzp1dF/mALxRY26c5FGGoDdWsJCzX1F++o35PcCqQ2lxlmiGeVXc37KI93h+JdYRtSdQ2j83lmLoLh/rL8bxd7xXSAsqe8nmTN71389a2l9qRp9H+UL/TA+sDHgGL0CTDTlboyaYryll8x3TOKEv3TQkbob4ZU/2cvA14TIkYEa2zBvN9F4gC0UVSpdDrmqQ0PoIxu6jfBNxH42Yr6oclbrKAGPCvFa8LTdhWuE9XbJ+KTgHt3cuUm4ikeb4JsfawF+zuryPOZxB/e0/59RfWpCHKF95qm9cY2jNJiC7g7x4IixUEPxxhr/JwIpe+TrMtpUs6HHBiDO7zNXG+zA+AnkP+HzBuC/SHj8TZYifoEw2/H3BPqymWsR3Nk1T/A6gBKCL5G50JAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "600 Route",
          "title": "600 Route",
          "src": "/static/bff2009756f5fdcb2d00dc68a4e7ce8f/1d69c/600.png",
          "srcSet": ["/static/bff2009756f5fdcb2d00dc68a4e7ce8f/4dcb9/600.png 188w", "/static/bff2009756f5fdcb2d00dc68a4e7ce8f/5ff7e/600.png 375w", "/static/bff2009756f5fdcb2d00dc68a4e7ce8f/1d69c/600.png 750w", "/static/bff2009756f5fdcb2d00dc68a4e7ce8f/78797/600.png 1125w", "/static/bff2009756f5fdcb2d00dc68a4e7ce8f/aa440/600.png 1500w", "/static/bff2009756f5fdcb2d00dc68a4e7ce8f/d9ed5/600.png 2880w"],
          "sizes": "(max-width: 750px) 100vw, 750px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`That's it? Well, yes. But let's zoom in on an area to demonstrate the connections a bit better (note, you can open the image and do this manutally).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "750px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.97872340425532%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAABJ0AAASdAHeZh94AAACCklEQVQoz3WQy27TYBCF/dRdIPEELCoQD8ACiQq6aEULpKg3qVKAJE2dxK4dJU3q2I3j/nZqO778tznIqcQCwdHs5nwzc8bodinPCAAevGQaS2esnFtlWZTnAAhwHVJCNYayxHycvj+UF5faHHY+TY1bWwPICxr6gCjFIuBhKE2TONeMIQgaTDfTaw5vAa1kVmLhbtJFahTX/SBD7czdty0Aj737+d4VnGHtuGIy0QT7Klb9PgBZ8JSJOnq666xQbTY1jHLixZFGldGHd6rVws4ORiYA5Tg6zwnQ4Wp5ZgU+QVX64544PQVbqdFocOIZlCTQWvdN2bvRdzOMb53Te8E1SIkfXXH0rdm5WMq00ABxTlFEBBCByGi+Esf05XPc6ujhCKSrUtM2pH2VbOZsm1mXGuNLD+vHprElATSwnAfe9yEFAWybOIcUDRD4MlwBJAVZLhAGyZSJiKl1gqpqDEoZmE6qbBMeXMrXb6jzi3Zf0dExgOLG4paLpR8/IcuBMp9Zee2HOvCRJFBKDwYGlALnNDLx8kV6dO5dh4CEa0+uIzZaAmCM0hTPx/4lA5wr3xeWjZ9tdngeh0XZvmk+MjRxsJ8pPPh6SzbCn3qGKwmwWLdOMOjPnHLV6pzt9prQpIuvF3lOD0uE4X82j6eg6BHHx8QY0kTNZwju7ts+607gLwioK1qv8U/9BtkpmnXRgHoeAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "600 Zoom",
          "title": "600 Zoom",
          "src": "/static/2ce61da7edec9dab629339c88d86e276/1d69c/600-zoom.png",
          "srcSet": ["/static/2ce61da7edec9dab629339c88d86e276/4dcb9/600-zoom.png 188w", "/static/2ce61da7edec9dab629339c88d86e276/5ff7e/600-zoom.png 375w", "/static/2ce61da7edec9dab629339c88d86e276/1d69c/600-zoom.png 750w", "/static/2ce61da7edec9dab629339c88d86e276/0a867/600-zoom.png 986w"],
          "sizes": "(max-width: 750px) 100vw, 750px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Now we can see how this provides useful routing information much better. Also notice how it cuts out teleporter locations we don't need to use.`}</p>
    <p>{`Furthermore, we can use this to select which locations are best to visit when N is small. Consider when N=200.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "750px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABxUlEQVQ4y5VU246dMAzk/z+ub32pVqpW2mrVPXuOgBByseOpHEgIC/RoIwVyccZjj5NOBLmJSO7aUiAkSlh3oMvtfvmfta4FK4Z+IrgxIkYgJQGRYBwFHPjg/ABYva4LCsAsoMCwJmGywOyAyQhSE80lQ1ksMgsFmm2CfXgkBWXAfDrMnzaPyXNe/x9oDjl6hjGAc0tYUYAwzDCvdwz3uDgQIHF6mscM6B4WbghwNoEDIYwO0SfMD5eB9KikDeCpKOqZXUCaPaJj2D4gxMVAcynOH8S4ZKi5MxPACZinBPth4L2Ks4kk3KqLA9PWUaf/Ioh2DY2pHDwPcxtudsVZt2aoGjT+Twt6uwioYBpdKb5uO7Qv8K/hbDYr27WrVuQi7N8xj7uzxO6u434HbUT6jQQ4EzG+9Yh8AbjLGRHE+zxXBqpPzreP8JazeLqmt0lzf81wZWcHgr+NkBChwk89LYcfPfqXG6jlLvI85Jrw8hKt1VDmbCymX2+wd5ftnocMqWyxCqOhVUAS0H0AvX9cA+7fyJPSORZknV8yPGW8gtHLb9D7DVxZS3X0LcBSLO7HT8TXP1kkNPWJ7wC2edXHpN6mL4/DP8BcMGw+w/tVAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "200 Route",
          "title": "200 Route",
          "src": "/static/a5fab322fa8c6d1c97c856aa20cb1a28/1d69c/200.png",
          "srcSet": ["/static/a5fab322fa8c6d1c97c856aa20cb1a28/4dcb9/200.png 188w", "/static/a5fab322fa8c6d1c97c856aa20cb1a28/5ff7e/200.png 375w", "/static/a5fab322fa8c6d1c97c856aa20cb1a28/1d69c/200.png 750w", "/static/a5fab322fa8c6d1c97c856aa20cb1a28/78797/200.png 1125w", "/static/a5fab322fa8c6d1c97c856aa20cb1a28/aa440/200.png 1500w", "/static/a5fab322fa8c6d1c97c856aa20cb1a28/d9ed5/200.png 2880w"],
          "sizes": "(max-width: 750px) 100vw, 750px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Now we can see that the algorithm selects clusters to naviage first. Furthermore, it suggest some points where the distance is so small it's worth the detour.`}</p>
    <h2>{`Discussing Some Outcomes`}</h2>
    <p>{`I think this can be a very useful tool for informing routing decisions when there are many locations to visit. However, this does not come with some drawbakcs.
These routing decisions are not exclusive to elite enemies as well. Traversing a map to get any kind of resource can be optimized in this fashion. `}</p>
    <p>{`For one, this does not account for the topology of the region. The most southern Tsurumi island has lots of mountains and caves. Traversing this location may actually be slower than other less mountainous regions.
We can actually overcome this issue if provided topological data (and x,y,z coordinates) by constructing a directed graph instead. `}</p>
    <p>{`Another problem is the issue of real-time teleports. If you have a slow computer, teleporting and loading may consume more time than simply going a farther distance. This issue is remedied with higher performing computers.`}</p>
    <h3>{`But That's it!`}</h3>
    <p>{`Thank you for your interest, and keep on speeding on.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      